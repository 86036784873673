/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Nunito Sans" !important;
}
.small-label{
  @apply   text-xxs sm:text-[10px] text-subtle font-extrabold uppercase;
}
.pageContainer {
  @apply max-w-[1280px] mx-auto sm:px-10 px-5;
}

.bg-oro-gradient {
  background: linear-gradient(180deg, #f9ca36 0%, #f8b830 100%);
}
